import React, { Component } from 'react';
import './Actions.css';
import { mimeType } from './Common.js';
import setResult, {
  withResult,
  fromResult,
  getResult,
  getResource,
  delResource,
} from './Result.js';
import history, {
  DoneUrl,
  InstructionsUrl,
  ErrBrowserUrl,
  ErrNetworkUrl,
} from './Urls.js';
import Raygun, { TAGS } from './Raygun';
import makeRequest, { makeRequestOut } from './Xhr.js';
import downArrow from './icons/down.svg';
//import imgBack from './icons/back.svg'
//import imgOval from './icons/oval.svg'
// TODO: move these constants to config
// chunkTime is the streaming chunk duration in milliseconds
const chunkTime = 5000;
// chunkTimeout is the duration of uploading a chunk in milliseconds
const chunkTimeout = chunkTime * 2;
// minObjectAvail is the number of minimally needed gcs objects; if it goes under this, getting new ones
const minObjectAvail = 2;
// getNewGscObjNum is the number of requested new GCS object urls when used all of them
const getNewGscObjNum = 6;
// gscMimetype is the mime type of the uploaded chunksto the Google Cloud Storage
const gscMimetype = 'video/webm';

// ActionState contains possible action screen states
const ActionState = {
  Prepare: 0,
  Action1: 1,
  Stopped: 100, // action states are lower than this
};
var savedColors; // colors to restore after leaving the action screen
const ActionTypes = {
  read_random_digits: {
    actionInstruction: 'Read each digit out loud',
    actionTextCls: 'actionNumbers',
    preprocess: function (input) {
      return input.split('').join(' - ');
    },
  },
  read_text: {
    actionInstruction: 'Read this text clearly',
    actionTextCls: 'actionText',
  },
  show_document: {
    actionInstruction: 'Show the document clearly and proceed',
    actionTextCls: 'showDocument',
    preprocess: function (input) {
      let subStrings = input.split('_');
      let data = '';
      for (let i = 1; i < subStrings.length; i++) {
        let word = subStrings[i];
        word = word.charAt(0).toUpperCase() + word.substr(1);
        if (i < subStrings.length - 1) {
          data = data + word + ' ';
        }
        if (i === subStrings.length - 1) {
          data = data + word;
        }
      }
      return data;
    },
  },
  // TODO: add gestures here
};

export default class Actions extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor
    this.state = {
      btnName: 'Start',
      title: 'Prepare for recording',
      actionInstruction: 'Position your face into the oval',
      actionText: 'Ensure your face is clearly visible',
      actionTextCls: 'subHeader',
      timer: '',
      showArrow: false,
    };
    this.imgOval = getResult('imgOval');
    this.actState = ActionState.Prepare;
    this.running = false;
    this.metadata = [];
    this.imgBack = getResult('imgBack');
    this.actionData = getResult('activity_array');
    setResult('uploadNum', 0); // number of chunks started to upload
    setResult('errNum', 0); // number of chunks started to upload
    setResult('used_object_ids', []);
    this.textScrolled = this.textScrolled.bind(this)
  }

  startNextAction() {
    if (!this.mediaRecorder) return;

    // starting next action
    const now = new Date();
    let actState = this.actState + 1;
    if (this.metadata.length > 0) {
      this.metadata[this.metadata.length - 1].end = now;
    }
    this.metadata.push({
      start: now,
    });
    const currAct = this.actionData[this.actState]; // action index starts from 0, one less than the current actState
    const actType = ActionTypes[currAct.type];
    let state = {
      actionText: currAct.data,
      title: 'Action ' + actState + ' of ' + this.actionData.length,
      actionInstruction: actType.actionInstruction,
      actionTextCls: actType.actionTextCls,
    };
    if ('preprocess' in actType) {
      state.actionText = actType.preprocess(state.actionText);
    }
    if (actType.actionTextCls === 'showDocument') {
      state.actionInstruction = state.actionText;
      state.actionText = actType.actionInstruction;
    }
    // finishing at the next button click
    if (actState === 1) {
      if (actState === this.actionData.length) {
        this.mediaRecorder.start(chunkTime);
        state.btnName = 'Done';
        actState = ActionState.Stopped;
        state.timer = <Timer />;
      } else {
        state.btnName = 'Next Action';
        this.mediaRecorder.start(chunkTime);
        state.timer = <Timer />;
      }
    } else if (actState === this.actionData.length) {
      actState = ActionState.Stopped;
      state.btnName = 'Done';
    }
    // progress update bar under the title
    const percentDone = (actState * 100) / this.actionData.length;
    this.refs.blackTitleSepStart.style.width = percentDone + '%';
    this.refs.blackTitleSepEnd.style.width = 100 - percentDone + '%';
    this.actState = actState;
    this.setState(state, () => {
      const elm = document.getElementById('action');
      if (actType.actionTextCls === "actionText") {
        this.setState({
          showArrow: elm.scrollHeight > elm.offsetHeight,
        })
        if (elm.scrollHeight > elm.offsetHeight) {
          elm.addEventListener('scroll', this.textScrolled);
        } else {
          elm.removeEventListener('scroll', this.textScrolled);
        }
      }
    });
  }

  // textScrolled is called when the user scrolls during the read_text action.
  textScrolled(e) {
    var x = e.target.scrollHeight - e.target.clientHeight;
    if (e.target.scrollTop <= x - 3) {
      this.setState({
        showArrow: true
      })
    } else {
      this.setState({
        showArrow: false
      })
    }
  }

  // nextClick is called when the user clicks on the main button. It may forward to the next action, or to the next screen.
  nextClick() {
    const now = new Date();
    // all done, continueing to the next screen
    if (this.actState === ActionState.Stopped) {
      this.metadata[this.metadata.length - 1].end = now;
      setResult('metadata', this.metadata);
      history.replace(DoneUrl);
      return;
    }

    this.startNextAction();
  }

  // dataAvailable is called upon a chunk is available from the streaming to upload.
  dataAvailable(e) {
    const blob = new Blob([e.data], { type: 'video/webm' });
    let formData = new FormData();
    formData.append('video-blob', blob);
    const GcsURLObject = fromResult('gcs_array', 'shift');
    if (!GcsURLObject) {
      history.replace(ErrNetworkUrl);
      return;
    }
    fromResult('used_object_ids', 'push', GcsURLObject);
    makeRequestOut(
      GcsURLObject.signed_url,
      'PUT',
      formData.get('video-blob'),
      chunkTimeout,
      gscMimetype,
    );
    if (fromResult('gcs_array', 'length') < minObjectAvail) {
      makeRequest('gcs_url/' + getNewGscObjNum).then(function (result) {
        withResult('gcs_array', 'concat', result);
      });
    }
  }

  // initStreaming makes the streaming available.
  initStreaming() {
    try {
      const video = document.getElementById('video');
      getResource('stream').then(
        function (stream) {
          if (stream === undefined) {
            setTimeout(this.initStreaming.bind(this), 150);
            return;
          }
          if (!video) {
            Raygun.addTags([
              TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
              TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON
                .TAG_NULL_VIDEO_ELEMENT,
            ]);
            history.replace(ErrBrowserUrl);
            if (stream != null) {
              stream.getTracks().map(val => val.stop());
            }
            return;
          }
          video.srcObject = stream;
          this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: mimeType,
          });
          this.mediaRecorder.ondataavailable = this.dataAvailable.bind(this);
        }.bind(this),
      );
    } catch (error) {
      history.replace(ErrBrowserUrl);
    }
  }

  closeCamera() {
    const video = document.getElementById('video');
    if (video) {
      const stream = video.srcObject;
      if (stream != null) {
        stream.getTracks().map(val => {
          val.stop();
          return null;
        });
      }
    }
  }

  // componentDidMount changes screen to dark theme, and initializes streaming.
  componentDidMount() {
    savedColors = [
      document.body.style.backgroundColor,
      document.body.style.color,
    ];
    document.body.style.backgroundColor = 'black';
    document.body.style.color = 'white';
    this.initStreaming();
  }

  // componentDidMount restores bright theme to the rest of the journey, and closes used resources.
  componentWillUnmount() {
    [
      document.body.style.backgroundColor,
      document.body.style.color,
    ] = savedColors;
    try {
      this.mediaRecorder.stop();
      getResource('stream').then(function (stream) {
        const tracks = stream.getTracks();
        for (let i in tracks) {
          tracks[i].stop();
        }
      });
    } catch { }
    this.closeCamera();
    delResource('stream');
  }
  backClick() {
    try {
      getResource('stream').then(function (stream) {
        const tracks = stream.getTracks();
        for (let i in tracks) {
          tracks[i].stop();
        }
      });
    } catch { }
    delResource('stream');
    history.replace(InstructionsUrl);
  }
  handleOval() {
    if (this.state.actionTextCls === 'showDocument') {
      return;
    } else {
      return <img className="vidMask" src={this.imgOval} alt="" />;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="blackTitle">
          <img
            src={this.imgBack}
            alt=""
            onClick={this.backClick.bind(this)}
            className="btnBack"
          />
          <div className="header">{this.state.title}</div>
        </div>
        <div className="blackTitleSep">
          <span ref="blackTitleSepStart" className="blackTitleSepStart" />
          <span ref="blackTitleSepEnd" className="blackTitleSepEnd" />
        </div>
        <div className="blackOval flex1">
          <video id="video" className="videoFeed" autoPlay muted />
          <div
            className="vidMask"
          // style={{ backgroundImage: `url(${imgOval})` }}
          >
            {this.handleOval()}
          </div>
          {this.state.timer}
        </div>
        <div className="actionContainer">
          <div className="header actionInstruction">
            {this.state.actionInstruction}
          </div>
          <div id="action" className={this.state.actionTextCls}>
            {this.state.actionText}
          </div>
        </div>
        <div className="arrow-container">
          {this.state.showArrow &&
            <>
              <p className="arrow-text">Scroll Down</p>
              <div className="arrow bounce">
                <img src={downArrow} alt="down arrow"></img>
              </div>
            </>
          }
        </div>
        <button disabled={this.state.showArrow} onClick={this.nextClick.bind(this)} className="primary flex0">
          {this.state.btnName}
        </button>
      </React.Fragment >
    );
  }
}

// zeroPad creates two digits by padding given number.
function zeroPad(n) {
  n = n + '';
  if (n.length < 2) {
    n = '0' + n;
  }
  return n;
}

// Timer shows a counting timer to the user while the recording-streaming is on.
class Timer extends React.Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    this.state = { minutes: 0, seconds: 0 };
    this.intervalHandle = setInterval(this.tick.bind(this), 1000);
  }

  // tick is called on every second to update the timer.
  tick() {
    let seconds = this.state.seconds + 1;
    let minutes = this.state.minutes;
    if (seconds >= 60) {
      seconds = 0;
      minutes += 1;
    }
    this.setState({ minutes: minutes, seconds: seconds });
  }

  // componentWillUnmount stops the timer.
  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }

  render() {
    return (
      <div id="videoTimer">
        <span className="recordCircle" />
        <span>
          {zeroPad(this.state.minutes)}:{zeroPad(this.state.seconds)}
        </span>
      </div>
    );
  }
}
