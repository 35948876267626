import React, { Component } from 'react';
import Wrap from './Common.js';
import './SmallScreen.css';
import somethingWentWrong from './icons/somethingWentWrong_2.svg';

export default class CameraNotConnected extends Component {
  render() {
    return (
      <div className="smallScreen errRecaptureScreen">
        <img className="img68" src={somethingWentWrong} alt="" />
        <div className="header24">Uh-oh! Something went wrong...</div>
        <Wrap
          className="header"
          text={
            "If that doesn't work, rest assured we're working on it and you can try again in a while"
          }
        />
      </div>
    );
  }
}
