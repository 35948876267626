import React from 'react';
import './SmallScreen.css';
import warning from './icons/warning.svg';
import example from './icons/Screenshot_20190827-134909 (1).jpg';

const NoPermissionPage = () => {
  const handleOnClickRecapture = () => {
    window.history.back();
  };

  return (
    <React.Fragment>
      <div className="smallScreen">
        <img src={warning} alt="" style={{ width: '6em', margin: '0 auto' }} />
        <br />
        <br />
        <br />
        <h5
          style={{ textAlign: 'center' }}
          className="errPermissionDeniedTitle"
        >
          Camera Permission Denied
        </h5>
        <br />
        <p className="steps" style={{ textAlign: 'start', fontWeight: 800 }}>
          Steps to continue
        </p>
        <p className="steps">
          1. Click the Lock icon on top-left of the screen.
        </p>
        <img src={example} alt="" style={{ height: '3em', width: 'inherit' }} />
        <p className="steps">2. Click &quot;Site Settings&quot;</p>
        <p className="steps">3. Click &quot;Clear & reset&quot;</p>
        <p className="steps">4. Click on &quot;Retry&quot; Button</p>
        <p className="steps" style={{ fontWeight: 100 }}>
          Note: If you didn&apos;t deny camera permission, kindly copy the link
          and open in Chrome Browser.
        </p>
      </div>
      <button onClick={handleOnClickRecapture} className="primary flex0">
        RETRY
      </button>
    </React.Fragment>
  );
};

export default NoPermissionPage;
