import React, { Component } from 'react';
import Wrap from './Common.js';
import history from './Urls.js';
import './SmallScreen.css';
import noCamera from './icons/noCamera.svg';

export default class CameraNotConnected extends Component {
  handleRetry() {
    history.goBack();
  }

  render() {
    return (
      <div className="smallScreen errRecaptureScreen">
        <img className="img68" src={noCamera} alt="" />
        <div className="header24">No Camera Connected</div>
        <Wrap
          className="header"
          text="There is no camera connected to this device. Kindly attach a webcam / enable camera for this browser and try again."
        />
        <br />
        <button onClick={this.handleRetry} className="primary flex0">
          RETRY
        </button>
      </div>
    );
  }
}
