import React, { Component } from 'react';
import { getResult } from './Result.js';
import makeRequest from './Xhr.js';
import history, { RedirectingUrl } from './Urls.js';
import './SmallScreen.css';

export default class Thankyou extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    // data to be sent to the InitKYC call
    const data = {
      geolocation: getResult('geolocation'),
      consent: getResult('accepted'),
      start: true,
      activity_id: getResult('activity_id'),
      metadata: getResult('metadata'),
      used_object_ids: getResult('used_object_ids'),
    };
    const jsonData = JSON.stringify(data);
    if (typeof data.activity_id !== 'undefined') {
      makeRequest('', 'PUT', jsonData).then(function(res) {
        if (res.message === 'ok') {
          setTimeout(function() {
            const url = getResult('url');
            if (url === undefined || url === '') return;
            history.push(RedirectingUrl);
          }, 1000);
        }
      });
    }
  }

  render() {
    return (
      <div className="smallScreen">
        <div className="header24">Video Submitted for Verification</div>
        <div className="header">Thank you for your time.</div>
      </div>
    );
  }
}
