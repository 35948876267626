import React, { Component } from 'react';
import Wrap from './Common.js';
import './SmallScreen.css';
import warning from './icons/warning.svg';
import helper from './helper';

export default class ErrCaptureExceeded extends Component {
  componentDidMount() {
    helper.goToRedirectPage();
  }

  render() {
    return (
      <div className="smallScreen errRecaptureScreen">
        <img className="img68" src={warning} alt="" />
        <div className="header24">Exceeded number of clicks</div>
        <Wrap
          className="header"
          text={
            'You have exceeded the number of times you can open the video kyc link.\nThe link will be no longer available.\nPlease contact your respective company for more details.'
          }
        />
      </div>
    );
  }
}
