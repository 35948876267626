import React, { Component } from 'react';
import makeRequest, { makeRequestStatic, setApiHome } from './Xhr.js';
import history, { InstructionsUrl, ErrBrowserUrl } from './Urls.js';
import setResult, { isSetResource, setResource, getResult } from './Result.js';
import imgOK from './icons/ok.svg';
import imgError from './icons/error.svg';
import imgActions from './icons/actions.svg';
import imgSpeaking from './icons/speaking.svg';
import imgWifi from './icons/wifi.svg';
import imgLight from './icons/light.svg';
import errConnection from './icons/errConnection.svg';
import imgOval from './icons/oval.svg';
import imgBack from './icons/back.svg';
import Raygun, { TAGS } from './Raygun';
// preloads contains resources to preload; TODO: add here more if you'd like
export const preloads = {
  imgOK,
  imgError,
  imgActions,
  imgSpeaking,
  imgWifi,
  imgLight,
  errConnection,
  imgBack,
  imgOval,
};

const goodNetwState = ['3g', '4g', '5g', '6g']; // TODO: update this when needed
// NetwState contains possible network states.
export const NetwState = {
  Poor: 0,
  Checking: 1,
  Unknown: 2,
  Good: 3,
};
let netwState = NetwState.Checking;
export function getNetwState() {
  return netwState;
}

// netwCheck checks current network state.
export function netwCheck() {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (netwState === NetwState.Unknown) {
    connection.onchange = netwCheck;
  }
  if (connection.effectiveType && connection.type === 'cellular') {
    if (goodNetwState.indexOf(connection.effectiveType.toLowerCase()) !== -1) {
      netwState = NetwState.Good;
    } else {
      netwState = NetwState.Poor;
    }
  } else {
    // otherwise we don't know it, the streaming will figure it out
    netwState = NetwState.Unknown;
  }
  if (connection.rtt <= 700) {
    netwState = NetwState.Good;
  } else {
    netwState = NetwState.Poor;
  }
  if (connection.rtt === 0) {
    if (navigator.onLine) {
      netwState = NetwState.Good;
    } else {
      netwState = NetwState.Poor;
    }
  }
}

// testing broke without this
window.MediaRecorder = window.MediaRecorder || {
  isTypeSupported(mimeType) {
    return false;
  },
};

// mimeType will contain the first supported mime type from the list
export var mimeType;
const mimeTypes = [
  'video/webm;codecs=vp9',
  'video/webm;codecs=vp8',
  'video/webm;codecs=h264',
  'video/webm',
];
for (const i in mimeTypes) {
  mimeType = mimeTypes[i];
  if (MediaRecorder.isTypeSupported(mimeType)) {
    break;
  }
}

const { navigator } = window;

// cloneAsObject creates a real object from something that can't be sent to JSON.stringify.
export function cloneAsObject(obj) {
  if (obj === null || !(obj instanceof Object)) {
    return obj;
  }
  const temp = obj instanceof Array ? [] : {};
  // ReSharper disable once MissingHasOwnPropertyInForeach
  for (const key in obj) {
    temp[key] = cloneAsObject(obj[key]);
  }
  return temp;
}

const listeners = {};

export function permCallbackIfAvailable(name, result) {
  if (name in listeners) {
    listeners[name](result);
  }
}

export function queryPerm(name, callback) {
  try {
    listeners[name] = callback;
    const callbackIfAvailable = function(result) {
      permCallbackIfAvailable(
        name,
        'target' in result ? result.target.state : result.state,
      );
    };
    if (!window.navigator.permissions) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON
          .TAG_NULL_NAVIGATOR_PERMISSIONS,
      ]);
      history.replace(ErrBrowserUrl);
    } else {
      window.navigator.permissions
        .query({ name })
        .then(function(result) {
          callbackIfAvailable(result);
          result.onchange = callbackIfAvailable;
        })
        .catch(error => {
          Raygun.addTags([
            TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
            TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON
              .TAG_ERR_PERMISSIONS_QUERY,
          ]);
          history.replace(ErrBrowserUrl);
        });
    }
  } catch (error) {
    Raygun.addTags([
      TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
      TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_ERR_QUERY_PERM,
    ]);
    history.replace(ErrBrowserUrl);
  }
}

export function removePermCallback(name) {
  delete listeners[name];
}

// grabResources starts querying geolocation for saving and camera-microphone for using.
export function grabResources() {
  // return new Promise((resolve,reject)=>{
  //   if(getResult('geolocation')){
  //     if (!isSetResource('stream')) {
  //       setResource(
  //         'stream',
  //         window.navigator.mediaDevices
  //           .getUserMedia({
  //             audio: true,
  //             video: true,
  //           })
  //           .catch(function() {
  //             if (window.location.pathname !== InstructionsUrl) {
  //               history.goBack()
  //             } else {
  //               permCallbackIfAvailable('camera', 'denied')
  //             }
  //           })
  //       )
  //     }
  //     resolve()
  //   }
  //   else{
  //     window.navigator.geolocation.getCurrentPosition(
  //       function(pos) {
  //         setResult('geolocation', JSON.stringify(cloneAsObject(pos)))
  //         if (!isSetResource('stream')) {
  //           setResource(
  //             'stream',
  //             window.navigator.mediaDevices
  //               .getUserMedia({
  //                 audio: true,
  //                 video: true,
  //               })
  //               .catch(function() {
  //                 if (window.location.pathname !== InstructionsUrl) {
  //                   history.goBack()
  //                 } else {
  //                   permCallbackIfAvailable('camera', 'denied')
  //                 }
  //               })
  //           )
  //         }
  //         resolve()

  //       },
  //       function(error) {
  //         // alert(error)
  //         if (error.code === error.PERMISSION_DENIED) {
  //           // geolocation permission denied, block user from the rest actions
  //           setResult('geolocation', false)
  //           if (history.location.pathname !== InstructionsUrl) {
  //             history.goBack()
  //           } else {
  //             permCallbackIfAvailable('geolocation', 'denied')
  //           }
  //         }
  //       }
  //     )
  //   }
  // })

  window.navigator.geolocation.getCurrentPosition(
    function(pos) {
      setResult('geolocation', JSON.stringify(cloneAsObject(pos)));
      // console.log(getResult("geolocation"));
    },
    function(error) {
      if (error.code === error.PERMISSION_DENIED) {
        // geolocation permission denied, block user from the rest actions
        setResult('geolocation', '');
        //   if (history.location.pathname !== InstructionsUrl) {
        //     history.goBack();
        //   } else {
        //     // console.log('reached here')
        //     permCallbackIfAvailable("geolocation", "denied");
        //   }
      }
    },
  );

  if (!isSetResource('stream')) {
    setResource(
      'stream',
      window.navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .catch(function(error) {
          if (error.name === 'NotAllowedError') {
            history.replace('/no_permission');
          } else if (window.location.pathname !== InstructionsUrl) {
            history.goBack();
          } else {
            permCallbackIfAvailable('camera', 'denied');
          }
        }),
    );
    setResult('audio_video', true);
  }
}

// returnConsent returns Consent text and other resources from resolved promise.
function returnConsent(res) {
  return {
    text: res.text,
    name: res.name,
    logo: res.logo,
  };
}

// setID sets Video KYC id.
export function setID(id, debug) {
  setResult('video_kyc_id', id);
  setApiHome(id, debug);
  const req = makeRequest('config');
  setResource('consent', req.then(returnConsent));
  req.then(function(res) {
    Raygun.setUser(id);
    // sets them as results for the rest
    setResult('logo', res.logo);
    setResult('actCount', res.act_count);
    if (!getResult('url')) {
      setResult('url', res.redirect_url);
    }
  });

  for (const i in preloads) {
    makeRequestStatic(preloads[i], i);
  }

  // Google Cloud Storage usable links to upload to
  setResult('gcs_array', []);
}

// Wrap is a dom component for wrapping text by newlines.
export default class Wrap extends Component {
  render() {
    return (
      <article className={this.props.className}>
        {this.props.text.split('\n').map(function(item, key) {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })}
      </article>
    );
  }
}
