import React, { Component } from 'react';
import './SmallScreen.css';
import browser from './icons/browser.svg';
import chrome from './icons/chrome.png';
import { getResult } from './Result.js';
// import firefox from './icons/firefox.jpg'

/**
 * Determine the operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', 'Mac OS', 'Windows', 'Linux', or null.
 */
export function getOperatingSystem() {
  const { navigator } = window;
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac OS';
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows';
  }
  if (/linux/i.test(platform)) {
    return 'Linux';
  }
  return null;
}

// [chrome, firefox] browser install webpage for the possible platforms
const browserInstallSOurce = {
  iOS: [
    'https://itunes.apple.com/us/app/google-chrome/id535886823',
    // 'https://itunes.apple.com/app/id989804926',
  ],
  Android: [
    'https://play.google.com/store/apps/details?id=com.android.chrome',
    // 'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
  ],
  'Windows Phone': ['', ''], // nothing, really
  'Mac OS': [
    'https://www.google.com/chrome/',
    // 'https://www.mozilla.org/en-US/firefox/new/',
  ],
  Windows: [
    'https://www.google.com/chrome/',
    // 'https://www.mozilla.org/en-US/firefox/new/',
  ],
  Linux: [
    'https://www.google.com/chrome/',
    // 'https://support.mozilla.org/en-US/kb/install-firefox-linux',
  ],
  null: ['', ''],
};

function copyButton(video_kyc_id) {
  if (video_kyc_id) {
    const home_link = getResult('home');
    const action = navigator.share ? 'Share' : 'Copy';
    const button = (
      <button
        onClick={() => handleCopyLink(home_link)}
        style={{
          width: '70%',
          backgroundColor: '#ffffff',
          color: '#4891e3',
          borderColor: '#4891e3',
          padding: '0.3em 0',
          marginBottom: '0.3em',
          fontSize: '1em',
        }}
        className="primary flex0"
      >
        {action} Link
      </button>
    );
    return button;
  }
  return null;
}

const shareLink = (shareTitle, shareText, captureLink) => {
  if (navigator.share) {
    navigator
      .share({
        title: shareTitle,
        text: shareText,
        url: captureLink,
      })
      .then(() => {})
      .catch(() => {});
  }
};

function handleChromeClick(link) {
  window.open(link[0]);
}

function chromeButton(link) {
  return (
    <button
      style={{
        width: '70%',
        alignSelf: 'flex-start',
        padding: '0.3em 0',
        fontSize: '1em',
      }}
      className="primary flex0"
      onClick={() => handleChromeClick(link)}
    >
      <img src={chrome} style={{ width: '1em', marginTop: '0' }} alt="" />
      <snap style={{ marginLeft: '.3em' }}> Open Chrome</snap>
    </button>
  );
}

const copyStringToClipboard = str => {
  // Get the snackbar DIV
  const x = document.getElementById('snackbar');

  // Add the "show" class to DIV
  x.className = 'show';

  // After 3 seconds, remove the show class from DIV
  setTimeout(function() {
    x.className = x.className.replace('show', '');
  }, 3000);

  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  // el.style = { position: "absolute", left: "-9999px" };
  el.style.cssText = 'position:absolute;left:-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

function handleCopyLink(capture_link) {
  if (navigator.share) {
    return shareLink('Video Kyc', 'Complete ', capture_link);
  }
  return copyStringToClipboard(capture_link);
}

export default class ErrBrowser extends Component {
  render() {
    const video_kyc_id = getResult('video_kyc_id');
    const links = browserInstallSOurce[getOperatingSystem()];
    const video_streaming_line = getResult('pre_browser_check_failed') ? (
      <>
        <p style={{ marginBottom: '.5em' }}>
          Your device/browser does not support video streaming.
        </p>
        <div style={{ textAlign: 'left' }}>
          <p style={{ marginBottom: 0 }}>
            Step 1: Copy link by clicking button below
          </p>
          {copyButton(video_kyc_id)}
          <p style={{ marginBottom: 0 }}>
            Step 2: Open Google Chrome browser. Paste the copied link and
            continue
          </p>
          {chromeButton(links)}
        </div>
      </>
    ) : (
      <>
        <div style={{ textAlign: 'left' }}>
          <p style={{ marginBottom: 0 }}>
            Step 1:Copy link by clicking button below
          </p>
          {copyButton(video_kyc_id)}
          <p style={{ marginBottom: 0 }}>
            Step 2: Open Google Chrome browser. Paste the copied link and
            continue
          </p>
          {chromeButton(links)}
        </div>
      </>
    );
    return (
      <>
        {getOperatingSystem() === 'iOS' ? (
          <div className="smallScreen">
            <img
              className="img68"
              src={browser}
              alt="browser incompatible error"
            />
            <div className="header28">Incompatbile device</div>
            <div className="header">
              Currently, iOS Devices are not supported.
            </div>
            <div className="header">Kindly try on a different device</div>
            <div id="snackbar">Link Copied</div>
          </div>
        ) : (
          <div className="smallScreen">
            <img
              className="img68"
              src={browser}
              style={{ marginBottom: '1em' }}
              alt="browser incompatible error"
            />
            <div className="header28" style={{ marginBottom: '.3em' }}>
              Browser not compatible
            </div>
            <div className="header">{video_streaming_line}</div>
            <div id="snackbar">Link Copied</div>
            {/* <a className="browserLink" href={links[1]}>
            <img src={firefox} alt="Mozilla Firefox logo" />
            <span>Mozilla Firefox</span>
          </a> */}
          </div>
        )}
      </>
    );
  }
}
