import React, { Component } from 'react';
import { getResult } from './Result.js';
import './SmallScreen.css';
import spinner from './icons/spinner.gif';

export default class Redirecting extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    let url = getResult('url');
    if (url === undefined) {
      url = 'idfy.com'; // fallback, just in case
    }
    if (url === '') {
      url = 'idfy.com'; // fallback, just in case
    }
    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }
    setTimeout(function() {
      window.location.replace(url);
    }, 1000);
  }

  render() {
    return (
      <div className="smallScreen">
        <img className="img110" src={spinner} alt="" />
        <div className="header28">Redirecting...</div>
      </div>
    );
  }
}
