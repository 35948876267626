import { createBrowserHistory } from 'history';

// url definitions
export const InstructionsUrl = '/instructions';
export const ActionsUrl = '/actions';
export const DoneUrl = '/done';
export const ThankyouUrl = '/thank_you';
export const RedirectingUrl = '/redirecting';
export const ErrNetworkUrl = '/network_error';
export const ErrCaptureExceededUrl = '/capture_exceeded';
export const ErrLinkExpiredUrl = '/link_expired';
export const ErrTaskDoneUrl = '/thank_you';
export const ErrTimeoutUrl = '/timeout';
export const ErrBrowserUrl = '/browser_incompatible';
export const ErrUnknownIDUrl = '/unknown_session';
export const ErrClicksExceededUrl = '/clicks_exceded';
export const CameraNotConnectedUrl = '/no_camera';
export const ErrSomethingWentWrongUrl = '/something_went_wrong';
export const ErrPermissionDeniedUrl = '/no_permission';

// history is an object to change URL path within the app, and to listen to URL path changes
const history = createBrowserHistory();
export default history;
