import history, {
  ErrUnknownIDUrl,
  ErrNetworkUrl,
  ErrLinkExpiredUrl,
  ErrTaskDoneUrl,
  ErrClicksExceededUrl,
  ErrCaptureExceededUrl,
} from './Urls.js';
import setResult, { getResult } from './Result.js';
import { preloads } from './Common';
// TODO: move these constants to config
// maxErrors is the allowed number of errors while uploading chunks
const maxErrors = 3;

//var apiHome = 'https://video-kyc-apis.idfystaging.com'
var apiHome = process.env.REACT_APP_VKYC_API_URL;
var videoKYCURL;
// currently running outside requests url => {request, content, method}
var collectedRequests = {};

// setApiHome sets the beginning of the API URLs based on the given Video KYC ID.
export function setApiHome(video_kyc_id, debug) {
  // if (!!debug) {
  //   apiHome = debugApiHome
  // }
  videoKYCURL = '/video_kycs/' + video_kyc_id + '/';
}

// ErrType contains possible error ID constants.
export const ErrType = {
  ReCaptureExceededT: 0,
  ClicksExceededT: 1,
  TaskNotFoundT: 2,
  ServerErrT: 3,
  InputErrT: 4,
  UnknownDbErrT: 5,
  UnknownErrT: 6,
  LinkExpiredT: 9,
  TaskDoneErrT: 7,
};

// isGlobalError returns if the error should be handled globally (true), or locally (false) at the current  caller Screen.
function isGlobalError(errType) {
  switch (errType) {
    case ErrType.ReCaptureExceededT:
      return true;
    default:
      return true;
  }
}

// parseReq tries to JSON parse XHR result.
function parseReq(req) {
  var result;
  try {
    result = JSON.parse(req.responseText);
  } catch {
    result = req.responseText;
  }
  return result;
}

// makeRequest creates an XHR outside and calls it.
export function makeRequestOut(url, method, content, timeout, contentType) {
  doMakeRequest(url, method, content, true, timeout, contentType)
    .then(function() {
      setResult('uploadNum', getResult('uploadNum') + 1);
    })
    .catch(function(result) {
      const errNum = getResult('errNum') + 1;
      setResult('errNum', errNum);
      if (errNum >= maxErrors) {
        abortOut();
        history.replace(ErrNetworkUrl);
      } else {
        makeRequestOut(url, method, content, timeout, contentType);
      }
    });
}

// abortOut aborts all requests outside the API.
function abortOut() {
  for (let i in collectedRequests) {
    try {
      collectedRequests[i].abort();
    } catch {}
  }
}

window.addEventListener('beforeunload', abortOut);

// makeRequest creates an XHR to the backend and calls it.
export default function makeRequest(url, method, content) {
  return doMakeRequest(apiHome + videoKYCURL + url, method, content).catch(
    function(data) {
      let catchResult = {
        status: data.request.status,
        statusText: data.request.statusText,
        errorType: null,
        errorText: '',
      };

      if (typeof data.result !== 'object' || !('error_type' in data.result)) {
        // unknown error format
        history.replace(ErrNetworkUrl);
        // handleUnknownError(data.result)
      } else if (isGlobalError(data.result.error_type)) {
        // task id not found, handle here
        switch (data.result.error_type) {
          case ErrType.TaskNotFoundT:
            history.replace(ErrUnknownIDUrl);
            break;
          case ErrType.LinkExpiredT:
            history.replace(ErrLinkExpiredUrl);
            break;
          case ErrType.TaskDoneErrT:
            history.replace(ErrTaskDoneUrl);
            break;
          case ErrType.ClicksExceededT:
            history.replace(ErrClicksExceededUrl);
            break;
          case ErrType.ReCaptureExceededT:
            history.replace(ErrCaptureExceededUrl);
            break;
          default:
            handleError(data.result);
        }
      } else {
        catchResult.errorType = data.result.error_type;
        catchResult.errorText = data.result.error_text;
      }
      return catchResult;
    },
  );
}
export async function makeRequestStaticSync() {
  for (let i in preloads) {
    var result = await doMakeRequest(preloads[i]);
    setResult(i, 'data:image/svg+xml;base64,' + btoa(result));
  }
}

// makeRequestStatic preloads a static resource.
export function makeRequestStatic(url, key) {
  return doMakeRequest(url).then(function(result) {
    setResult(key, 'data:image/svg+xml;base64,' + btoa(result));
  });
}

function doMakeRequest(url, method, content, collect, timeout, contentType) {
  var request = new XMLHttpRequest(); // create XHR request
  var timer = null;
  if (typeof timeout === 'number' && timeout > 0) {
    timer = setTimeout(function() {
      request.abort();
    }, timeout);
  }
  return new Promise(function(resolve, reject) {
    // return as a Promise
    request.onreadystatechange = function() {
      // setup listener to process compeleted requests
      if (request.readyState !== 4) {
        return; // only run if the request is complete
      }

      if (timer) {
        clearTimeout(timer);
      }

      if (request.status >= 200 && request.status < 300) {
        // successful
        if (collect) {
          delete collectedRequests[url];
        }
        resolve(parseReq(request));
      } else {
        // failed
        const result = parseReq(request);
        reject({ result: result, request: request, url: url });
      }
    };

    if (collect) {
      collectedRequests[url] = request;
    }
    request.open(method || 'GET', url, true); // setup HTTP request
    if (contentType) {
      request.setRequestHeader('Content-Type', contentType);
    }
    if (!content) {
      // undefined, null, empty string
      request.send(); // send the request
    } else {
      request.send(content);
    }
  });
}

// handleError handles errors that shouldn't be directly communicated to the user.
function handleError(error) {
  // error is an object having 'error_type' and 'error_text', defined in backend/errors.go
  alert('going to a custom error screen, hiding this: ' + error.error_text); // TODO: replace with an error screen saying something general about the error
}

// handleUnknownError handles errors that are not in the API format. This is really rare.
// function handleUnknownError(error) {
//   // we don't know much about this error: it can be a string, or an object
//   alert('unknown error, what to do? ' + JSON.stringify(error)) // TODO handle somehow else
// }
