import React, { Component } from 'react';
import history from './Urls.js';
import Wrap from './Common.js';
import './SmallScreen.css';
import timeout from './icons/errTimeout.svg';

export default class ErrTimeout extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="smallScreen errTimeoutScreen">
          <img className="img60" src={timeout} alt="" />
          <div className="header24">Time out</div>
          <Wrap
            className="header"
            text={
              'It seems you took long to input your data the session has timed out.\nPlease contact your company for more details'
            }
          />
        </div>
        <button onClick={history.goBack} className="primary flex0">
          RETRY
        </button>
      </React.Fragment>
    );
  }
}
