import React, { Component } from 'react';
import { getResult, fromResult } from './Result.js';
import history, { ThankyouUrl } from './Urls.js';
import './SmallScreen.css';
import './Consent.css';
import spinner from './icons/spinner.gif';

export default class Done extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    this.logo = getResult('logo');
    this.state = {
      disabledPrimary: true,
      loader: <img className="img110" src={spinner} alt="" />,
    };
    // interval to check if the uploads have been done.
    this.interval = setInterval(this.checkResult.bind(this), 80);
  }

  // checkResult checks if all uploads have been done.
  checkResult() {
    if (fromResult('used_object_ids', 'length') === getResult('uploadNum')) {
      this.setState({ disabledPrimary: false, loader: '' });
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // nextClick goes forward to the next screen.
  nextClick() {
    if (this.state.disabledPrimary) {
      return;
    }
    history.push(ThankyouUrl);
  }

  render() {
    return (
      <React.Fragment>
        <div className="smallScreen">
          <img className="doneImg" src={this.logo} id="companyLogo" alt="" />
          <div className="header24">Video successfully recorded</div>
          <div className="header">
            Press “Submit” to initiate your KYC process
          </div>
          {this.state.loader}
        </div>

        <button
          onClick={this.nextClick.bind(this)}
          disabled={this.state.disabledPrimary}
          className="primary flex0"
        >
          Submit
        </button>
        <button onClick={history.goBack} className="secondary flex0">
          Re capture
        </button>
      </React.Fragment>
    );
  }
}
