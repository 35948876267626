import React, { Component } from 'react';
import Wrap from './Common.js';
import './SmallScreen.css';
import expired from './icons/expired.svg';
import helper from './helper';

export default class ErrLinkExpired extends Component {
  componentDidMount() {
    helper.goToRedirectPage();
  }

  render() {
    return (
      <div className="smallScreen errRecaptureScreen">
        <img className="img68" src={expired} alt="" />
        <div className="header24">Page expired</div>
        <Wrap
          className="header"
          text="Please note the link is active for 24 hours. Kindly request for a new link to complete your Video KYC."
        />
      </div>
    );
  }
}
