import rg4js from 'raygun4js';
import pjson from '../package.json';
import helper from './helper';
import { getOperatingSystem } from './ErrBrowser';

export const TAGS = {
  ERROR_PAGE: {
    BROWSER_NOT_SUPPORTED: {
      TAG: 'error-page: BROWSER_NOT_SUPPORTED',
      REASON: {
        // `getUserMedia()` not supported
        TAG_NSUP_GET_USER_MEDIA: 'error-reason: NSUP_GET_USER_MEDIA',
        // HTML5 video tag not supported
        TAG_NSUP_HTML5_VIDEO: 'error-reason: NSUP_HTML5_VIDEO',
        // Video element returned null in DOM
        TAG_NULL_VIDEO_ELEMENT: 'error-reason: NULL_VIDEO_ELEMENT',
        // Cannot detect browser type
        TAG_BROWSER_TYPE_NOT_DETECTED:
          'error-reason: BROWSER_TYPE_NOT_DETECTED',
        // Browser is black listed
        TAG_BLACK_LISTED: 'error-reason: BLACK_LISTED',
        // Error in `nextClick()` in Instructions.js
        TAG_ERR_NEXT_CLICK: 'error-reason: ERR_NEXT_CLICK',
        // Error in `nextClickHelper()` in instructions.js
        TAG_ERR_NEXT_CLICK_HELPER: 'error-reason: ERR_NEXT_CLICK_HELPER',
        // Camera not available
        TAG_CAMERA_NOT_AVAILABLE: 'error-reason: CAMERA_NOT_AVAILABLE',
        // `window.navigator.permissions` returned null
        TAG_NULL_NAVIGATOR_PERMISSIONS:
          'error-reason: NULL_NAVIGATOR_PERMISSIONS',
        // Error in `window.navigator.permissions.query`
        TAG_ERR_PERMISSIONS_QUERY: 'error-reason: ERR_PERMISSIONS_QUERY',
        // Error in `queryPerm()` in Common.js
        TAG_ERR_QUERY_PERM: 'error-reason: ERR_QUERY_PERM',
      },
    },
  },
};

const init = () => {
  rg4js('apiKey', process.env.REACT_APP_RAYGUN_API_KEY);
  rg4js('enableCrashReporting', true);
  rg4js('enablePulse', true);
  // rg4js("options", {
  //   debugMode: true
  // });
  rg4js('setVersion', pjson.version);
  rg4js('saveIfOffline', true);
  rg4js('withTags', [
    `browser: ${helper.browserType()}`,
    `os: ${getOperatingSystem()}`,
    `device: ${helper.deviceName()}`,
    `devicetype: ${helper.deviceType()}`,
    `browser-version: ${helper.browserType()} - ${helper.BrowserVersion()}`,
    `OS-version: ${getOperatingSystem()} - ${helper.OSversion()}`,
  ]);
};

const setUser = profileId => {
  rg4js('setUser', {
    identifier: profileId,
    isAnonymous: false,
  });
};

const pageView = pathname => {
  rg4js('trackEvent', { type: 'pageView', path: pathname });
};

const addTags = tags => {
  rg4js('withTags', tags);
};

const breadcrumbMiddleware = store => next => action => {
  const previousState = store.getState();
  const nextAction = next(action);
  const state = store.getState();

  rg4js('recordBreadcrumb', {
    message: `Executing Redux action '${action.type}'`,
    metadata: {
      action,
      state,
      previousState,
    },
    level: 'info',
  });

  return nextAction;
};

export default {
  init,
  setUser,
  pageView,
  addTags,
  breadcrumbMiddleware,
};
