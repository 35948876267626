import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import WebFont from 'webfontloader';
import ReactGA from 'react-ga';
import App from './App';
import * as serviceWorker from './serviceWorker';
import raygun from './Raygun';

function initializeReactGA() {
  ReactGA.initialize(`${process.env.REACT_APP_GA_KEY}`, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
}

if (process.env.NODE_ENV === 'production') {
  // Initialize raygun for crash reporting and user monitoring
  if (process.env.REACT_APP_ENABLE_RAYGUN === 'true') {
    raygun.init();
  }
  initializeReactGA();
}

WebFont.load({
  google: {
    families: ['Roboto:300,400,400i,700', 'sans-serif'],
  },
});
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
