import whichBrowser from 'which-browser';
import history, { RedirectingUrl } from './Urls.js';
import { getResult } from './Result.js';

const userAgentData = {
  result: {
    os: {
      version: null,
    },
    browser: {
      name: null,
      version: null,
    },
    device: {
      model: null,
      type: null,
    },
  },
  userAgent: navigator.userAgent,
};
try {
  const whichBrowserObj = new whichBrowser(navigator.userAgent);
  try {
    userAgentData.result.device.type = whichBrowserObj.device.type;
  } catch (error) {}
  try {
    userAgentData.result.device.model = whichBrowserObj.device.model;
  } catch (error) {}
  try {
    userAgentData.result.browser.name = whichBrowserObj.browser.name;
  } catch (error) {}
  try {
    userAgentData.result.browser.version =
      whichBrowserObj.browser.version.value;
  } catch (error) {}
  try {
    userAgentData.result.os.version = whichBrowserObj.os.version.value;
  } catch (error) {}
} catch (error) {}

const browserType = () => {
  const browserArr = [
    { browserString: 'OppoBrowser/', browser: 'Oppo Browser' },
    { browserString: 'VivoBrowser/', browser: 'Vivo Browser' },
    { browserString: 'RealmeBrowser/', browser: 'Realme Browser' },
    { browserString: 'XiaoMi/MiuiBrowser/', browser: 'MIUI Browser' },
    { browserString: 'UCBrowser/', browser: 'UC Browser' },
    { browserString: 'SamsungBrowser/', browser: 'Samsung Browser' },
    { browserString: 'brave/', browser: 'Brave Browser' },
    { browserString: 'BlackBerry', browser: 'Blackberry Browser' },
    { browserString: 'bing', browser: 'Bing app' },
    { browserString: 'Dolfin/', browser: 'Dolphin Browser' },
    { browserString: 'DuckDuckGo/', browser: 'DuckDuckGo Browser' },
    { browserString: 'HuaweiBrowser/', browser: 'Huawei Browser' },
    { browserString: 'IEMobile', browser: 'IE Mobile' },
    { browserString: 'Edge/', browser: 'Edge' },
    { browserString: 'EdgiOS/', browser: 'Edge iOS' },
    { browserString: 'EdgA/', browser: 'Edge Android' },
    { browserString: 'Opera Mini/', browser: 'Opera Mini' },
    { browserString: 'WhatsApp', browser: 'WhatsApp Browser' },
  ];
  for (const i in browserArr) {
    if (userAgentData.userAgent.search(browserArr[i].browserString) !== -1) {
      return browserArr[i].browser;
    }
  }
  return userAgentData.result.browser.name;
};
const deviceName = () => {
  return userAgentData.result.device.model;
};
const deviceType = () => {
  return userAgentData.result.device.type;
};
const OSversion = () => {
  return userAgentData.result.os.version;
};
const BrowserVersion = () => {
  return userAgentData.result.browser.version;
};

const goToRedirectPage = () => {
  const source = getResult('source');
  const url = getResult('url');
  if (source === 'kyc_gateway' && url) {
    setTimeout(function() {
      history.push(RedirectingUrl);
    }, 2000);
  }
};

export default {
  browserType,
  deviceName,
  OSversion,
  deviceType,
  BrowserVersion,
  goToRedirectPage,
};
