import React, { Component } from 'react';
import Wrap from './Common.js';
import './SmallScreen.css';
import invalid from './icons/invalid.svg';

export default class ErrUnknownID extends Component {
  render() {
    return (
      <div className="smallScreen">
        <img className="img68" src={invalid} alt="" />
        <div className="header24">Invalid Link</div>
        <Wrap
          className="header"
          text="This link seems to be invalid.Kindly contact support to continue"
        />
      </div>
    );
  }
}
