import React, { Component } from 'react';
import WhiteTitle from './WhiteTitle.js';
import Wrap from './Common.js';
import { makeRequestStaticSync } from './Xhr';
import setResult, { getResource, getResult } from './Result.js';
import history, { InstructionsUrl } from './Urls.js';
import './Consent.css';

export default class Consent extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor
    this.state = { text: '', name: '', logo: '' };

    this.skipConsentAllowedSource = ['kyc_gateway'];

    getResource('consent').then(
      function(res) {
        this.setState(res);
        const { search } = window.location;
        const rx1 = new RegExp('[?&]consent=([^&]*)');
        const metch_result1 = search.match(rx1);
        const source = getResult('source');
        if (
          metch_result1 &&
          metch_result1.length === 2 &&
          metch_result1[1] === 'skip' &&
          source &&
          this.skipConsentAllowedSource.indexOf(source) !== -1
        ) {
          setResult('accepted', true); // just to make sure
          makeRequestStaticSync().then(() => {
            history.push(InstructionsUrl);
          });
        }
      }.bind(this),
    );
    // if anything goes wrong, it's handled in Common.js and Xhr.js
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { search } = window.location;
    const rx1 = new RegExp('[?&]consent=([^&]*)');
    const metch_result1 = search.match(rx1);
    const source = getResult('source');
    if (
      metch_result1 &&
      metch_result1.length === 2 &&
      metch_result1[1] === 'skip' &&
      source &&
      this.skipConsentAllowedSource.indexOf(source) !== -1
    ) {
      return false;
    }
    return true;
  }

  // nextClick goes forward to the next screen.
  nextClick() {
    setResult('accepted', true); // just to make sure
    history.push(InstructionsUrl);
  }

  render() {
    let imgLogo = '';
    if (this.state.logo.length !== 0) {
      imgLogo = <img src={this.state.logo} id="companyLogo" alt="" />;
    }
    return (
      <React.Fragment>
        <WhiteTitle title="Video KYC consent" />
        <div className="subHeader flex0" id="companyHeader">
          {imgLogo}
          <span id="companyName">{this.state.name}</span>
        </div>
        <Wrap className="consent flex1" text={this.state.text} />
        <button onClick={this.nextClick} className="primary flex0">
          Agree and Continue
        </button>
      </React.Fragment>
    );
  }
}
