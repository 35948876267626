import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Consent from './Consent';
import Instructions from './Instructions';
import Actions from './Actions';
import Done from './Done';
import setResult from './Result';
import Thankyou from './Thankyou';
import Redirecting from './Redirecting';
import ErrNetwork from './ErrNetwork';
import ErrBrowser from './ErrBrowser';
import ErrTimeout from './ErrTimeout';
import ErrCaptureExceeded from './ErrCaptureExceeded';
import ErrUnknownID from './ErrUnknownID';
import ErrLinkExpired from './ErrLinkExpired';
import ErrClicksExceeded from './ErrClickExceeded';
import CameraNotConnected from './CameraNotConnected';
import ErrSomethingWentWrong from './ErrSomethingWentWrong';
import ErrPermissionDenied from './ErrPermissionDenied';
import Raygun, { TAGS } from './Raygun';

import './App.css';
import { setID } from './Common';
import history, {
  InstructionsUrl,
  ActionsUrl,
  DoneUrl,
  ThankyouUrl,
  ErrNetworkUrl,
  ErrBrowserUrl,
  ErrTimeoutUrl,
  RedirectingUrl,
  ErrCaptureExceededUrl,
  ErrUnknownIDUrl,
  ErrLinkExpiredUrl,
  ErrClicksExceededUrl,
  ErrTaskDoneUrl,
  CameraNotConnectedUrl,
  ErrSomethingWentWrongUrl,
  ErrPermissionDeniedUrl,
} from './Urls';

let StartScreen; // the screen url to start with
const ScreenContent = {}; // url => screen descriptors
ScreenContent[InstructionsUrl] = Instructions;
ScreenContent[ActionsUrl] = Actions; // replaceUrl: true,
ScreenContent[DoneUrl] = Done;
ScreenContent[ThankyouUrl] = Thankyou;
ScreenContent[RedirectingUrl] = Redirecting;
ScreenContent[ErrNetworkUrl] = ErrNetwork;
ScreenContent[ErrTimeoutUrl] = ErrTimeout;
ScreenContent[ErrBrowserUrl] = ErrBrowser;
ScreenContent[ErrCaptureExceededUrl] = ErrCaptureExceeded;
ScreenContent[ErrUnknownIDUrl] = ErrUnknownID;
ScreenContent[ErrLinkExpiredUrl] = ErrLinkExpired;
ScreenContent[ErrTaskDoneUrl] = Thankyou;
ScreenContent[ErrClicksExceededUrl] = ErrClicksExceeded;
ScreenContent[CameraNotConnectedUrl] = CameraNotConnected;
ScreenContent[ErrSomethingWentWrongUrl] = ErrSomethingWentWrong;
ScreenContent[ErrPermissionDeniedUrl] = ErrPermissionDenied;
// dontGoBack is a list of final screens, where we don't navigate the user back
const dontGoBack = [
  ThankyouUrl,
  RedirectingUrl,
  ErrUnknownIDUrl,
  ErrBrowserUrl,
  ErrCaptureExceededUrl,
  ErrLinkExpiredUrl,
  ErrTaskDoneUrl,
  ErrClicksExceededUrl,
  CameraNotConnectedUrl,
  ErrSomethingWentWrongUrl,
  ErrPermissionDeniedUrl,
];

// getQueryVariable returns a variable by key from the path parameters(search).
function getQueryVariable(variable) {
  if (window.location.search.length === 0) {
    return null;
  }
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

// getBrowserType returns the current browser name, if it's Chrome (or Firefox). Otherwise it returns empty string.
function getBrowserType() {
  const { navigator } = window;
  const vendor = ((navigator && navigator.vendor) || '').toLowerCase();
  const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase();
  // alert(window.navigator.connection.effectiveType)
  // alert('vendor:--' + vendor + '\nuserAgent:----' + userAgent)

  if (
    /google inc/.test(vendor) &&
    userAgent.match(/(?:chrome|crios)\/(\d+)/) &&
    !userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/)
  ) {
    return 'Chrome';
  }

  // if (userAgent.match(/(?:firefox|fxios)\/(\d+)/) !== null) {
  //   return 'Firefox'
  // }

  // TODO: add more types if needed
  return '';
}

function blackListBrowserDetect() {
  const keyWordsToFind = [
    'XiaoMi/MiuiBrowser/',
    'VivoBrowser/',
    'RealmeBrowser/',
    'OppoBrowser/',
    'UCBrowser/',
    'SamsungBrowser/',
  ];
  const rowData = navigator.userAgent;
  return keyWordsToFind.some(key => rowData.includes(key));
}

export default class App extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    if ('screen' in window && 'lockOrientation' in window.screen) {
      // lock portrait for mobile phones
      window.screen.lockOrientation('portrait-primary');
    }

    if (window.location.pathname.length < 3) {
      this.state = this.initWithId(null);
      return;
    }

    const url = window.location.pathname;
    if (url in ScreenContent) {
      if (dontGoBack.indexOf(url) !== -1) {
        // shouldn't go back
        StartScreen = url;
        this.state = { screen: ScreenContent[StartScreen] };
        return;
      }
      this.state = {};
      this.preUnlisten = history.listen(
        function(location) {
          if (location.pathname in ScreenContent) {
            history.goBack();
          } else {
            this.preUnlisten();
            this.setState(this.getIDFromUrl());
          }
        }.bind(this),
      );
      history.goBack();
    } else {
      this.state = this.getIDFromUrl();
    }
  }

  // getIDFromUrl gets the last string between two slashes of the current url.
  getIDFromUrl() {
    let url = window.location.pathname;
    setResult('home', window.location.href);
    ScreenContent[url] = Consent;
    StartScreen = url;
    if (url.substr(url.length - 1) !== '/') {
      url += '/';
    }
    const parts = url.split('/');
    if (parts.length !== 4) {
      return this.initWithId(null);
    }
    this.get_redirect_uri_from_url(window.location.search);
    return this.initWithId(parts[2]);
  }

  get_redirect_uri_from_url(search) {
    const rx1 = new RegExp('[?&]redirect_uri=([^&]*)');
    const rx2 = new RegExp('[?&]source=([^&]*)');
    const metch_result1 = search.match(rx1);
    const metch_result2 = search.match(rx2);
    if (
      metch_result1 &&
      metch_result1.length === 2 &&
      metch_result2 &&
      metch_result2.length === 2
    ) {
      setResult('url', decodeURIComponent(metch_result1[1]));
      setResult('source', metch_result2[1].toString());
    } else if (metch_result1 && metch_result1.length === 2) {
      setResult('url', decodeURIComponent(metch_result1[1]));
    } else if (metch_result2 && metch_result2.length === 2) {
      setResult('source', metch_result2[1].toString());
    }
  }

  // method to chech that the browser is compatible for the application or not
  // Checks - browserType.lenght
  //        - blacklistedBrowser
  //        - with the use of modernizr check video tag is support or not
  //        - with the use of modernizr check getusermedia is support or not
  compatibilityChecks() {
    const browserType = getBrowserType();
    if (browserType.length === 0) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON
          .TAG_BROWSER_TYPE_NOT_DETECTED,
      ]);
      setResult('pre_browser_check_failed', true);
      return false;
    }
    if (blackListBrowserDetect()) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_BLACK_LISTED,
      ]);
      setResult('pre_browser_check_failed', true);
      return false;
    }
    if (!window.Modernizr.video) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_NSUP_HTML5_VIDEO,
      ]);
      setResult('pre_browser_check_failed', true);
      return false;
    }
    if (!window.Modernizr.getusermedia) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_NSUP_GET_USER_MEDIA,
      ]);
      setResult('pre_browser_check_failed', true);
      return false;
    }
    setResult('pre_browser_check_failed', false);
    return true;
  }

  initWithId(video_kyc_id) {
    if (video_kyc_id === null) {
      StartScreen = ErrUnknownIDUrl;
    } else {
      setID(video_kyc_id, getQueryVariable('debug'));
      if (!this.compatibilityChecks()) {
        // history.replace(ErrBrowserUrl);
        StartScreen = ErrBrowserUrl;
        // this.setState({ screen: ScreenContent[ErrBrowserUrl] });
        Raygun.pageView(ErrBrowserUrl);
      }
    }

    for (const url in ScreenContent) {
      // loading screen descriptors
      const scr = ScreenContent[url];
      scr.ref = `r${url.substr(1)}`;
    }
    this.unlisten = history.listen(this.histListener.bind(this));
    return { screen: ScreenContent[StartScreen] };
  }

  reactGAPageView() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidMount() {
    this.reactGAPageView();
  }

  // componentWillUnmount is the component destructor.
  componentWillUnmount() {
    this.unlisten(); // unlisten history
  }

  // histListener is called when history changes
  histListener(location, action) {
    this.setState({ screen: ScreenContent[location.pathname] });
    Raygun.pageView(location.pathname);
  }

  render() {
    let scr;
    if ('screen' in this.state) {
      scr = <this.state.screen />;
    }
    return (
      <React.Fragment>
        {scr}
        <div className="footer flex0">Powered by IDfy</div>
      </React.Fragment>
    );
  }
}
