const result = {}; // globally shared values
const resource = {}; // globally shared resources that come from a promise
const resProm = {}; // globally shared resources' promises

// setResult saves globally shared results.
export default function setResult(key, value) {
  result[key] = value;
}

// fromResult runs a function on a globally shared result, and returns value.
export function fromResult(key, func, ...args) {
  let r = result[key][func];
  if (typeof r === 'function') {
    r = r.apply(result[key], args);
  }
  return r;
}

// withResult runs a function on a globally shared result, and resets value with the result.
export function withResult(key, func, ...args) {
  const r = result[key][func](...args);
  result[key] = r;
}
export function removeUndefined() {
  const result = getResult('gcs_array').filter(function(gcsObject) {
    return gcsObject != null;
  });
  setResult('gcs_array', result);
}
// getResult returns globally shared results.
export function getResult(key) {
  return result[key];
}

// getResult returns globally shared resources, that are created as promises.
export function getResource(key) {
  if (key in resource) {
    return Promise.resolve(resource[key]);
  }
  return resProm[key];
}

export function setResource(key, p) {
  resProm[key] = p;
  p.then(function(value) {
    resource[key] = value;
    // if(key === 'stream'){
    //   console.log("enterd into stream permison")
    //   console.log(getResult('geolocation'))
    //   if(!getResult('geolocation')){
    //     console.log("enter  ")
    //     getResource('stream').then(function(stream) {
    //       const tracks = stream.getTracks()
    //       for (let i in tracks) {
    //         tracks[i].stop()
    //       }
    //     })
    //     delResource('stream')
    //   }
    // }
    delete resProm[key];
  });
}

export function delResource(key) {
  delete resProm[key];
  delete resource[key];
}

export function isSetResource(key) {
  return resource[key] || resProm[key];
}
