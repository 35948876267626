import React, { Component } from 'react';
import history from './Urls.js';
import './SmallScreen.css';
import { getResult } from './Result.js';
import { netwCheck, NetwState, getNetwState } from './Common.js';

export default class ErrNetwork extends Component {
  constructor(props) {
    super(props);
    this.errConnection = getResult('errConnection');
  }

  nextClick() {
    netwCheck();
    if (getNetwState() === NetwState.Good) {
      history.goBack();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="smallScreen">
          <img className="img60" src={this.errConnection} alt="" />
          <div className="header28">Check your network</div>
          <div className="header">
            It seems you are in low network connectivity. To complete this KYC
            you need to have 3G/4G network connectivity
          </div>
        </div>
        <button onClick={this.nextClick} className="primary flex0">
          RETRY
        </button>
      </React.Fragment>
    );
  }
}
