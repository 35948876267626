import React, { Component } from 'react';
import './WhiteTitle.css';

export default class WhiteTitle extends Component {
  render() {
    return (
      <div className="whiteTitle header flex0">
        <div>{this.props.title}</div>
        <div className="whiteTitleSep">
          <span className="whiteTitleSepStart" />
          <span className="whiteTitleSepEnd" />
        </div>
      </div>
    );
  }
}
