import React, { Component } from 'react';
import WhiteTitle from './WhiteTitle.js';
import {
  grabResources,
  getNetwState,
  NetwState,
  netwCheck,
  queryPerm,
  removePermCallback,
} from './Common.js';
import setResult, { withResult, getResult, removeUndefined } from './Result.js';
import history, { ActionsUrl, ErrNetworkUrl, ErrBrowserUrl } from './Urls.js';
import makeRequest from './Xhr.js';
import './Instructions.css';
import Raygun, { TAGS } from './Raygun';

// PermState contains the possible permission states.
const PermState = {
  Denied: 0,
  Unknown: 1,
  Prompt: 2,
  Granted: 3,
};
// resolveState maps browser strings to PermState enum.
const resolveState = {
  denied: PermState.Denied,
  prompt: PermState.Prompt,
  granted: PermState.Granted,
};

const permNames = ['geolocation', 'camera', 'microphone']; // permission names to check

export default class Instructions extends Component {
  constructor(props) {
    super(props); // Required step: always call the parent class' constructor

    this.imgOK = getResult('imgOK');
    this.imgError = getResult('imgError');
    this.imgActions = getResult('imgActions');
    this.imgSpeaking = getResult('imgSpeaking');
    this.imgWifi = getResult('imgWifi');
    this.imgLight = getResult('imgLight');

    this.actCount = getResult('actCount');
    this.perms = {}; // permission name => state, started to evaluate at componentDidMount

    // clickedBtn shows if the user clicked on 'Continue' button at least once.
    this.clickedBtn = false;

    this.state = { minPerm: PermState.Unknown };
    this.gotActions = false;
    // there's no specific error handling to this call
    makeRequest('activity').then(
      function(result) {
        setResult('activity_id', result.activity_id);
        setResult('activity_array', result.activity_array);
        withResult('gcs_array', 'concat', result.gcs_array);
        removeUndefined(); // this removes undefined in gcs_array
        this.gotActions = true;
        // this.goForward()
      }.bind(this),
    );

    netwCheck();
  }

  // componentDidMount initializes permission states
  componentDidMount() {
    for (let i in permNames) {
      // console.log(permNames);
      this.queryPermission(permNames[i]);
    }
  }

  componentWillUnmount() {
    for (let i in permNames) {
      // console.log(permNames);
      removePermCallback(permNames[i]);
    }
  }

  // goForward checks if everything is given to move to the next screen, and does when it's OK.
  goForward(minPerm) {
    if (
      this.perms['camera'] === 3 &&
      this.perms['microphone'] === 3 &&
      this.perms['geolocation'] === 2 &&
      typeof minPerm === 'undefined'
    ) {
      grabResources();
      history.push(ActionsUrl);
    }

    if (typeof minPerm === 'undefined') {
      minPerm = this.state.minPerm;
    }
    if (
      minPerm === PermState.Granted &&
      this.clickedBtn === true &&
      this.gotActions === true
    ) {
      grabResources();
      history.push(ActionsUrl);
    }
  }

  // permChange is called when a permission change is detected. It iterates all permissions,
  // saving the minimum value, so the component can decide its state from it.
  permChange(resultState, name) {
    this.perms[name] = resultState;
    let result = PermState.Granted;
    for (let i in this.perms) {
      // looking for the minimum value
      if (this.perms[i] < result || name !== 'geolocation') {
        result = this.perms[i];
      }
    }
    // if (result === PermState.Denied) {
    //   history.push(ErrPermissionDeniedUrl);
    // }
    this.setState({ minPerm: result });
    // TODO: future Firefox enabling: Firefox may give a one-time permission while staying in 'prompt' state
    this.goForward(result);
  }

  // queryPermission asynchronously queries a permission, and saves result to state.
  queryPermission(name) {
    queryPerm(
      name,
      function(result) {
        this.permChange(resolveState[result], name);
      }.bind(this),
    );
  }

  nextClickHelper() {
    try {
      // if (this.state.minPerm === PermState.Denied) {
      //   return; // disabled permissions are blocked
      // }
      if (getNetwState() === NetwState.Poor) {
        history.push(ErrNetworkUrl);
        return;
      }
      grabResources();
      this.clickedBtn = true;
      this.goForward();
    } catch {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_ERR_NEXT_CLICK_HELPER,
      ]);
      history.push(ErrBrowserUrl);
    }
    // grabResources().then(()=>{
    //   console.log("done")
    //   this.clickedBtn = true
    //   this.goForward()
    // })
  }

  // nextClick is called when the user clicks on continue.
  nextClick() {
    const that = this;
    try {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          const camera_available = devices.some(eachDevice => {
            return eachDevice.kind === 'videoinput';
          });
          if (camera_available) {
            this.nextClickHelper();
          } else {
            Raygun.addTags([
              TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
              TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON
                .TAG_CAMERA_NOT_AVAILABLE,
            ]);
            history.push(ErrBrowserUrl);
          }
        })
        .catch(function(error) {
          that.nextClickHelper();
        });
    } catch (error) {
      Raygun.addTags([
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.TAG,
        TAGS.ERROR_PAGE.BROWSER_NOT_SUPPORTED.REASON.TAG_ERR_NEXT_CLICK,
      ]);
      history.push(ErrBrowserUrl);
    }
  }

  render() {
    let permText = [
      'Allow permissions on your device to enable camera, location, microphone',
    ];
    let permCls = [''];
    if (this.state.minPerm === PermState.Denied) {
      permText.push(
        'You seem to have denied permission for the above. Please provide permission from browser settings to continue.',
      );
      permCls.push('instCustom');
    }
    const permTexts = permText.map((txt, k) => (
      <span key={k} className={permCls[k]}>
        {txt}
      </span>
    ));
    let actionString = '';
    if (this.actCount === 1) {
      actionString = 'activity';
    } else {
      actionString = 'activities';
    }
    return (
      <React.Fragment>
        <WhiteTitle title="Instructions" />
        <div className="flex1">
          <div id="preInstructions" className="instCustom">
            READ these instructions carefully before starting
          </div>
          <div className="instructionsLong">
            <img
              src={this.imgActions}
              alt="needed action"
              className="instImgLong"
            />
            <span>
              You'll be asked to take a selfie-video performing {this.actCount}{' '}
              {actionString}{' '}
            </span>
          </div>
          <div className="instructionsSep" />
          <div className="instructions">
            <img
              src={this.imgSpeaking}
              alt="speak out loud"
              className="instImg"
            />
            <span>Speak out loud</span>
          </div>
          <div className="instructionsSep" />
          <div className="instructions">
            <img
              src={this.imgWifi}
              alt="have a good internet connection"
              className="instImg"
            />
            <span>Be connected to high-speed internet (3G/4G/Wifi)</span>
          </div>
          <div className="instructionsSep" />
          <div className="instructions">
            <img
              src={this.imgLight}
              alt="have good enlightening"
              className="instImg"
            />
            <span>
              Ensure your face is clearly visible in the screen that follows
            </span>
          </div>
          <div className="instructionsSep" />
          <div className="instructions">
            <img
              src={
                this.state.minPerm !== PermState.Denied
                  ? this.imgOK
                  : this.imgError
              }
              alt="permissions"
              className="instImg"
            />
            <div>{permTexts}</div>
          </div>
        </div>
        <span className="instLabel flex0">
          {this.state.minPerm !== PermState.Denied
            ? 'Press “Continue” to give permissions'
            : 'READY ?'}
        </span>
        <button
          onClick={this.nextClick.bind(this)}
          // disabled={this.state.minPerm === PermState.Denied}
          className="primary flex0"
        >
          Continue
        </button>
      </React.Fragment>
    );
  }
}
